import React from 'react';
import Parallax from '../../components/Parallax/Parallax';
import './Quote.css';

const Quote = (props) => {
  return (
    <div id="section2" className="container light-text">
      <h3 className="text-center"><span className="italic">"{props.quote}"</span></h3>
    </div>
  );
}

export default Quote;
